 /* BODY */
body {
  margin: 0px;
  box-sizing: border-box;
}
.center {
  text-align: center;
}

/* ARAppDetails */
.ARAppDetails {
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 16em;
  padding-right: 4em;
}
.ARAppDetailsURLField {
  margin-left: 2em!important;
  min-width: 400px!important;
}
.ARAppDetailsDivider {
  margin-top: 3em!important;
  margin-bottom: 1em!important;
}
.ARAppDetailsButtonsContainer {
  margin-top: 2em;
  text-align: center;
}
.ARAppDetailsButton {
  margin-left: 1em!important;
  margin-right: 1em!important;
}
.ARAppDetailsNoticeAddLayer {
  display: inline;
}
.ARAppDetailsAddLayerSelector {
  display: inline;
  margin-left: 8px;
  vertical-align: 14px;
}
.ARAppDetailsTextFieldCode textarea{
  font-family: Courier New, Courier, monospace;
  font-weight: bold
}


/* ARAppsPage */
.ARAppsPageContainer {
  display: flex;
}
.ARAppPageLeftColumn {
  min-width: 300px;
  z-index: 1;
  background-color: white;
}
.ARAppPageRightColumn {
  flex-grow: 1;
  min-height: calc(100vh - 48px);
}
.ARAppPageNotice {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  max-width: 800px;
  padding: 2em;
}
.ARAppPageNoticeNoApps {
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 2em;
  margin-bottom: 4em;
  padding: 2em;
}
.ARAppsPageAddFloatButton {
  float: right;
  margin-top: -28px!important;
  margin-right: -28px!important;
}


/* ARLayerDetails */
.ARLayerDetails {
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 16em;
  padding-right: 4em;
}
.ARLayerDetailsButtonsContainer {
  margin-top: 2em;
  text-align: center;
}
.ARLayerDetailsButton {
  margin-left: 1em!important;
  margin-right: 1em!important;
}
.ARLayersDetailsAddFloatButton {
  float: right;
  margin-top: -28px!important;
  margin-right: -28px!important;
}
.ARLayerDetailsDivider {
  margin-top: 3em!important;
  margin-bottom: 1em!important;
}
.ARLayerDetailsChangeImage {
  flex-grow: 1;
  align-self: flex-end;
  text-align: center;
  margin-bottom: 8px;
}
.ARLayerDetailsImageDisplayContainer {
  display: flex;
  flex-direction: column;
}
.ARLayerDetailsImageDisplay {

}
.ARLayerDetailsDeleteImage {
  align-self: flex-end;
  margin-bottom: 8px;
}


/* ARLayersPage */
.ARLayersPageContainer {
  display: flex;
}
.ARLayerPageLeftColumn {
  min-width: 300px;
  z-index: 1;
  background-color: white;
}
.ARLayerPageRightColumn {
  flex-grow: 1;
  min-height: calc(100vh - 48px);
}
.ARLayerPageNotice {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  max-width: 800px;
  padding: 2em;
}
.ARLayerPageNoticeNoLayers {
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 2em;
  padding: 2em;
  margin-bottom: 4em;
}
.ARLayersPageAddFloatButton {
  float: right;
  margin-top: -28px!important;
  margin-right: -28px!important;
}


/* ErrorSnackBar */
.ErrorSnackBarContent {
  background-color: #cc0000!important;
  font-weight: bold!important;
}

.ErrorSnackBarIcon {
  padding-right: 1em;
}

.ErrorSnackBarMessage {
  display: flex;
  align-items: center
}


/* LayerSelector */
.LayerSelector {
  display: inline;
}
.LayerSelectorSelect {
}
.LayerSelectorSelect .MuiSelect-selectMenu {
  height: 1.2em;
}


/* LoginPage */
.LoginPageLeftImage {
  background-image: url("../images/loginPageBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center
}
.LoginPageRight {
  margin: 64px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.LoginPageAvatar {
  margin: 8px;
  background-color: black;
}
.LoginPageGoogleButton {
  margin-top: 0em;
  font-size: 1rem!important;
  padding: 22px 22px 22px 22px;
}
.LoginPageLogo {
  max-width: 300px;
}
.LoginPageDescription {
  text-align: center;
  line-height: 2em!important;
  margin-top: 2em!important;
  margin-bottom: 3em!important;
}
.LoginPageDivider {
  margin-top: 3em!important;
}


/* ListMenu */
.ListMenuIcon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.ListMenuList {
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}


/* LoginPage */
.LoginPage {
  height: 100vh;
}


/* NavBar */
.NavBarAppBarContainer {
  height: 48px;
}
.NavBarAppBar {
  position: fixed!important;
  display: flex!important;
  flex-direction: row!important;
}


/* Welcome */
.WelcomeContainer {
  text-align: center;
}
.WelcomeContent {
  max-width: 800px!important;
}
.WelcomeContent > div {
  height: auto!important;
}
.WelcomeLogoImage {
  max-width: 300px;
}
.WelcomeDescription {
  text-align: left;
}
.WelcomeDescription > li {
  margin-top: 1em;
}